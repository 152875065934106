import { render, staticRenderFns } from "./examination-audit.vue?vue&type=template&id=4b47ae22&scoped=true&"
import script from "./examination-audit.vue?vue&type=script&lang=js&"
export * from "./examination-audit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b47ae22",
  null
  
)

export default component.exports